import classNames from "classnames";

export interface Props {
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean | undefined;
}

export const CheckBox = ({ checked, onChange, disabled }: Props) => {
  return (
    <div className="flex flex-row gap-2 items-center">
      <input
        className={classNames("w-5 h-5 accent-call", {
          "cursor-pointer": !disabled,
          "cursor-not-allowed": disabled,
        })}
        type="radio"
        disabled={disabled}
        checked={checked}
        onClick={e => {
          e.stopPropagation();
        }}
        onChange={(e) => onChange(e.target.checked)}
      />
    </div>
  );
}