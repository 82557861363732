import {DropdownOption} from "@/components/Dropdown";
import classNames from "classnames";
import {useScreen} from "@/util/useScreen";
import {twMerge} from "tailwind-merge";
import {useIsDesktop} from "@/hooks/useIsDesktop";

export interface Props<T> {
  label?: string,
  options: DropdownOption<T>[],
  value: T,
  onChange: (value: T) => void,
  className?: string,
  borderlessSelected?: boolean,
  optionClassName?: string,
}

export function Tabs<T extends any>(props: Props<T>) {
  const isDesktop = useIsDesktop();
  const showNormalLabel = useScreen('md');
  const { label = "", options, value, onChange, className = "" } = props;
  return <div className={twMerge("flex border-[1px] border-neutral text-sm rounded-md items-center gap-2", className || "")}>
    {label && <span className="text-neutral-content text-sm font-light">{label}: </span>}
    <div className="flex flex-row items-center w-full h-full">
      {
        options.map((option, i) =>
          <button onClick={() => onChange(option.value)}
                  key={`tab_${option.value}`}
                  className={classNames(`responsive-flex items-center justify-center md:gap-2 uppercase basis-0 h-full flex-grow`, {
                    "border-t md:border-t-0 bg-glass text-primary-content": props.borderlessSelected && value === option.value,
                    "border-t md:border-b md:border-t-0 text-primary-content": !props.borderlessSelected && value === option.value,
                    "text-neutral-content hover:bg-glass": value !== option.value,
                    "p-2": !isDesktop,
                  })}>
            {
              option.icon &&
              <div className={classNames({
                "text-lg": !isDesktop,
              })}>
                {option.icon}
              </div>
            }
            <div className="h-full md:p-2 flex items-center">
              <span className={twMerge("text-[8px] md:text-sm tracking-widest", props.optionClassName || "")}>
                {!showNormalLabel && option.shortLabel ? option.shortLabel : option.label}
              </span>
            </div>
          </button>)
      }
    </div>
  </div>
}