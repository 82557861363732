import { ethers } from "ethers";
import Link from "next/link";
import {FaCheckCircle, FaSwimmingPool} from "react-icons/fa";
import {twMerge} from "tailwind-merge";
import {isBlast, isBNPL} from "@/util/constants";
import {useEnsAvatar, useEnsName} from "wagmi";
import {Address} from "viem";
import React, {useState} from "react";
import Blockies from 'react-blockies';
import {useCurrentUser} from "@/hooks/useCurrentUser";
import {useClusterName} from "@/hooks/useClusterName";
import {BiSolidCopy} from "react-icons/bi";
import {getBlockExplorer} from "@/util/chainConstants";

const getEtherscanUrl = (address?: string) => {
  return `${getBlockExplorer()}/address/${address}`;
}

const getPoolUrl = (address?: string) => {
  if (address && isBNPL(address)) {
    return getEtherscanUrl(address);
  }
  return `/pool/${address}`;
}

export interface Props {
  address: string;
  label?: string;
  short?: boolean;
  type?: 'etherscan' | 'pool';
  className?: string;
  hideAddressOnSelf?: boolean;
  showAvatar?: boolean;
  displaySelf?: boolean;
  useBlockie?: boolean;
  blockieSize?: number;
  displayIcon?: boolean;
  copyAllowed?: boolean;
}

export const AddressDisplay = ({
  address,
  label,
  short = false,
  type,
  className = '',
  hideAddressOnSelf = false,
  showAvatar = false,
  displaySelf = true,
  useBlockie = false,
  displayIcon = false,
  blockieSize = 8,
  copyAllowed
}: Props) => {
  const { address: userAddress } = useCurrentUser();
  const { data: name } = useEnsName({
    address: address as Address,
    query: { enabled: !isBlast }
  });

  const { data: avatar} = useEnsAvatar({
    name : name as string,
    query : { enabled: showAvatar && !isBlast }
  })

  const { data: clusterName } = useClusterName({address: address as Address});

  const [copied, setCopied] = useState<boolean>(false);

  const handleCopyText = () => {
    navigator.clipboard.writeText(address).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    });
  };

  const getUrl = () => {
    switch (type) {
      case "pool":
        return getPoolUrl(address);
      default:
        return getEtherscanUrl(address);
    }
  }

  let addressToDisplay = address;
  if (short) {
    addressToDisplay = "0x" + address.slice(2, 8);
  }

  if (address === ethers.constants.AddressZero) {
    return <div className={twMerge("font-light", className || "")}>{addressToDisplay}</div>;
  }
  const getLabel = (): string => {
    if (clusterName) {
      return clusterName;
    }
    if (label) {
      return label;
    }
    if (name) {
      if (short && name.length > 16) {
        return name.toLowerCase().slice(0, 12) + "...";
      }
      return name.toLowerCase();
    }
    const isSelf = userAddress?.toLowerCase() === address.toLowerCase();
    return (displaySelf && isSelf ? "(You) " : "") + (isSelf && hideAddressOnSelf ? '' : addressToDisplay);
  }
  return (
    <Link href={getUrl()} target="_blank" className={twMerge("hover:text-white hover:underline font-light", className || '')} suppressHydrationWarning>
      <div className="flex flex-row items-center justify-start gap-1" suppressHydrationWarning>
        {
          (showAvatar && avatar)
            ? <img src={avatar} alt="ens_avatar" className={`w-${blockieSize || 8} h-${blockieSize || 8} rounded-full mr-1`}/>
            : useBlockie
              ? <Blockies seed={address} size={blockieSize || 8} scale={4} className="mr-1 rounded-full" />
              : undefined
        }
        { type === "pool" ? <FaSwimmingPool /> : null}
        {
          displayIcon
            ? <img src="/static/etherscan-logo-circle-light.svg"
                   alt="img"
                   className="h-4 w-4 md:h-6 md:w-6 md:hover:scale-110" />
            : (getLabel() + " ")
        }
        {/*{ !displayIcon && <BiLinkExternal/> }*/}
        {
          copyAllowed &&
          <div className="text-neutral-content hover:text-white cursor-pointer"
               onClick={e => {
                 e.preventDefault();
                 e.stopPropagation();
                 handleCopyText();
               }}>
            { copied ? <FaCheckCircle /> : <BiSolidCopy/> }
          </div>
        }
      </div>
    </Link>
  );
};
