import "react-tooltip/dist/react-tooltip.css";

import classNames from "classnames";
import React, {useEffect, useRef, useState} from "react";
import {TiArrowSortedDown, TiArrowSortedUp, TiArrowUnsorted} from "react-icons/ti";
import {twMerge} from "tailwind-merge";
import {BigNumber} from "ethers";
import Link from "next/link";
import {useScreen} from "@/util/useScreen";
import { Tooltip as ReactTooltip } from "react-tooltip";
import {BiHelpCircle} from "react-icons/bi";
import {ErrorPanel} from "@/components/ErrorPanel";
import {theme} from "../tailwind.config";
import Switch from "react-switch";
import {CheckBox} from "@/components/CheckBox";

type SortFunction<T> = (item1: T, item2: T, dir: "asc" | "desc" | null) => number;

export function bigNumberSort<T>(extractor: (value: T) => BigNumber): SortFunction<T> {
  return (a, b, dir) => {
    const i1 = extractor(a);
    const i2 = extractor(b);
    const c = i1.eq(i2) ? 0 : (i1.gt(i2) ? 1 : -1);
    return c * (dir === 'asc' ? 1 : -1);
  }
}

export function bigIntSort<T>(extractor: (value: T) => bigint): SortFunction<T> {
    return (a, b, dir) => {
        const i1 = extractor(a);
        const i2 = extractor(b);
        const c = i1 === i2 ? 0 : (i1 > i2 ? 1 : -1);
        return c * (dir === 'asc' ? 1 : -1);
    }
}

export function numberSort<T>(extractor: (value: T) => number): SortFunction<T> {
  return (a, b, dir) => {
    const i1 = extractor(a);
    const i2 = extractor(b);
    const c = i1 - i2;
    return c * (dir === 'asc' ? 1 : -1);
  }
}

export function stringSort<T>(extractor: (value: T) => string): SortFunction<T> {
  return (a, b, dir) => {
    const i1 = extractor(a);
    const i2 = extractor(b);
    const c = i2.localeCompare(i1);
    return c * (dir === 'asc' ? 1 : -1);
  }
}

export function tableColumn<T>(
  id: string,
  label: string,
  valueRenderer: (pool: T, index: number) => any,
  span = 1,
  alignRight?: boolean,
  sort?: SortFunction<T>,
  columnOnClick?: (label: string) => void,
): TableColumn<T> {
  return { id, label, valueRenderer, span, align: alignRight ? 'right' : 'left', sort, columnOnClick };
}

export interface TableColumn<T> {
  id: string;
  label: string | React.ReactNode;
  valueRenderer: (data: T, index: number, dir: "asc" | "desc" | null) => any;
  span?: number;
  sort?: (item1: T, item2: T, dir: "asc" | "desc" | null) => number;
  fetchSort?: () => any;
  align?: 'left' | 'center' | 'right';
  hideOnMobile?: boolean;
  onlyShowOnMobile?: boolean;
  tooltip?: string | React.ReactNode;
  columnOnClick?: (label: string) => void;
}

export interface TableSelectController<T> {
  selected: T[];
  toggle: (item: T) => void;
  clear: () => void;
  disabled?: (item: T) => boolean | undefined;
  isSelected: (item: T) => boolean;
}

interface Props<T> {
  id: string;
  columns: TableColumn<T>[];
  data: T[] | undefined;
  className?: string;
  isLoading: boolean;
  error?: string | undefined;
  rowHref?: (data: T) => string;
  rowOnClick?: (data: T) => void;
  emptyText?: any;
  actionContent?: any,
  actionPressed?: () => void,
  actionLink?: string;
  initialSort?: string;
  initialSortDirection?: "asc" | "desc" | null;
  extraRow?: React.ReactNode,
  extraRowIndex?: number;
  firstColumnSticky?: boolean;
  selectController?: TableSelectController<T> | undefined;
  fetchSort?: string;
  mobileRowRenderer?: (data: T) => React.ReactNode;
  rowClassName?: string;
}

export const useTableSelect = <T extends any>(
  multiSelect = false,
  comparator?: (item1: T, item2: T) => boolean,
  disabled?: (item: T) => boolean | undefined
): TableSelectController<T> => {
  const [selected, setSelected] = useState<T[]>([]);
  const isSelected = (item: T) => selected.find(i => comparator ? comparator(i, item) : i === item) !== undefined;
  const toggle = (item: T) => {
    if (multiSelect) {
      if (isSelected(item)) {
        setSelected(selected.filter(i => i !== item));
      } else {
        setSelected([...selected, item]);
      }
    } else {
      setSelected(isSelected(item) ? [] : [item]);
    }
  }
  return { selected, toggle, clear: () => setSelected([]), disabled, isSelected }
}

const calculateGridCols = (columns: TableColumn<any>[], isDesktop: boolean): string => {
  const length = columns
    .filter(c => {
      if (isDesktop) {
        return c.onlyShowOnMobile === undefined || !c.onlyShowOnMobile;
      }
      return !c.hideOnMobile;
    })
    .reduce((a, b) => a + (b.span === undefined ? 1 : b.span), 0);
  return `grid-cols-${length}`;
}

export function Table<T extends any>(
  {
    id,
    columns,
    data,
    className = '',
    isLoading,
    error,
    rowOnClick,
    rowHref,
    emptyText,
    actionPressed,
    actionLink,
    actionContent,
    initialSort,
    initialSortDirection,
    extraRowIndex,
    extraRow,
    firstColumnSticky,
    selectController,
    fetchSort,
    mobileRowRenderer,
    rowClassName
  }: Props<T>
) {
  const isDesktop = useScreen('sm');
  const [gridCols, setGridCols] = useState(calculateGridCols(columns, isDesktop));
  useEffect(() => {
    setGridCols(calculateGridCols(columns, isDesktop));
  }, [isDesktop]);

  // @ts-ignore
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (extraRow && ref.current&& !isLoading) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  }, [ref, isLoading]);

  const [sort, setSort] = useState<TableColumn<T> | undefined>(columns.find(c => c.id === initialSort));
  const [sortDir, setSortDir] = useState<"asc" | "desc" | null>(initialSortDirection || (sort ? "desc" : null));
  const renderSortIcon = (header: TableColumn<T>) => {
    if (header.fetchSort && header.id === fetchSort) {
      return <TiArrowSortedDown />;
    }

    if (header.id !== sort?.id || sortDir === null) {
      return <TiArrowUnsorted />;
    } else if (header.id === sort.id) {
      return sortDir === "asc" ? <TiArrowSortedUp /> : <TiArrowSortedDown />;
    }
    return null;
  };

  const filterColumn = (column: TableColumn<any>): boolean => {
    if (isDesktop) {
      return !column.onlyShowOnMobile;
    }
    return column.onlyShowOnMobile || !column.hideOnMobile;
  }

  const getData = () => {
    const shouldSort =
      sort !== undefined && sort.sort !== undefined && sortDir !== undefined && sortDir !== null;
    if (shouldSort) {
      return [...data!].sort((a, b) => sort!.sort!(a, b, sortDir));
    }
    return data!;
  }
  const renderRow = (entry: T, i: number) => {
    const content = (
      <div
        // key={`table_row_${i}`}
           onClick={e => {
             if (rowOnClick) {
               rowOnClick(entry);
             }
           }}
           id={`table_row_${i}`}
           className={twMerge(classNames(`grid ${gridCols} gap-2 table_row grid-rows-1 border-neutral border-dotted p-4 box-border w-full`, {
             "border-t-2": i > 0,
             "border-b-2": data && (i === data!.length - 1),
             "hover:cursor-pointer hover:bg-glass":( rowHref || rowOnClick ) && isDesktop,
             "bg-glass-focus": selectController?.isSelected(entry)
           }), rowClassName || '')}>
        {
          columns.filter(filterColumn).map((column, j) =>
            <div key={`table_cell_${i}_${j}`}
                 className={classNames(`flex items-center gap-2 ${column.span ? `col-span-${column.span}` : 'col-span-1'}`, {
                   "justify-end": column.align === 'right',
                   "justify-center": column.align === 'center',
                   "sticky left-0 z-10 bg-[#0e1a2c]": firstColumnSticky && (j === 0 && !isDesktop),
                 })}>
              {
                selectController && j === 0 &&
                <CheckBox
                  disabled={selectController.disabled?.(entry)}
                  checked={selectController.isSelected(entry)}
                  onChange={e => {
                    selectController?.toggle(entry)
                  }}/>
              }
              {column.valueRenderer(entry, i + 1, sortDir)}
            </div>)
        }
      </div>
    );
    if (rowHref) {
      return (
        <Link href={rowHref(entry)} key={`table_row_link_${i}`} className={`table_row_link`}>
          {content}
        </Link>
      )
    }
    return content;
  }
  return (
    <div id={id} className={twMerge("pb-4 sm:pb-0 glassborder rounded-md flex flex-col", className || "")}>
      {
        (isDesktop || mobileRowRenderer === undefined) &&
        <div className={`grid ${gridCols} grid-rows-1 p-4 font-light text-neutral-content text-sm border-b-[1px] border-neutral`}>
          {columns.filter(filterColumn).map((column, i) => (
          <div
            id={`table_header_${column.id}`}
            key={`table_header_${column.id}`}
            onClick={() => {
              if (column.columnOnClick) {
                column.columnOnClick(column.id);
              }
              if (column.fetchSort) {
                column.fetchSort();
                return;
              }
              if (!column.sort) {
                return;
              }
              if (column.id !== sort?.id) {
                setSort(column);
                setSortDir("desc");
              } else {
                setSortDir(sortDir === "desc" ? "asc" : sortDir === "asc" ? null : "desc");
              }
            }}
            className={classNames(`text-xs cursor-default flex items-center gap-1 col-span-${column.span ? column.span : 1}`, {
              "justify-end text-right": column.align === "right",
              "justify-center text-center": column.align === "center",
              "text-white": (sort?.id === column.id && sortDir !== null) || column.fetchSort && column.id === fetchSort,
              "hover:cursor-pointer hover:text-white": (column.sort !== undefined || column.fetchSort || column.tooltip !== undefined) && isDesktop,
              "sticky left-0 z-10 bg-[#0e1a2c]": firstColumnSticky && (i === 0 && !isDesktop)
            })}
          >
            <span>{column.label}</span>
            {(column.sort || column.fetchSort) && renderSortIcon(column)}
            {column.tooltip && (
              <>
                <BiHelpCircle id={`table_header_${column.id}_tooltip`} className="w-4 h-4" />
                <ReactTooltip anchorSelect={`#table_header_${column.id}`}
                              className="z-40 text-center max-w-56"
                              place="bottom">
                  {column.tooltip}
                </ReactTooltip>
              </>
            )}
          </div>
        ))}
        </div>
      }
      <div className="w-full overflow-y-auto no-scrollbar">
        {isLoading && (
          <div className="mx-4 my-6 flex flex-col gap-6">
            {[...Array(5)].map((v, i) => (
              <div key={`table_skeleton_loading_${i}`} className="h-9 bg-slate-600 animate-pulse rounded-md"></div>
            ))}
          </div>
        )}
        {error && (
          <div className="p-4">
            <ErrorPanel message={error} />
          </div>
        )}
        {data && (
          <div className="w-full no-scrollbar">
            {data.length === 0 && !isLoading && <div className="w-full my-4 text-center">{emptyText || "No Data"}</div>}
            {getData().map((v, i) => (
              (isDesktop || mobileRowRenderer === undefined) ?
                <div key={`table_row_${i}`}>
                  {extraRowIndex !== undefined && extraRowIndex === i && (
                    <div ref={ref} key={`table_extra_row_${i}`}>
                      {extraRow}
                    </div>
                  )}
                  {renderRow(v, i)}
                </div> :
                <div key={`table_row_${i}`} className={classNames("p-6 border-neutral border-dotted box-border", {
                  "border-t-2": i > 0,
                  "border-b-2": data && (i === data!.length - 1),
                })}>
                  {mobileRowRenderer(v)}
                </div>
            ))}
            {actionContent && actionPressed && !isLoading && (
              <div
                className="box-border w-full"
                onClick={() => actionPressed && actionPressed()}
              >
                {actionContent}
              </div>
            )}
            {actionContent && actionLink && !isLoading && (
              <Link href={actionLink}>
                <div className="box-border w-full hover:bg-glass">
                  {actionContent}
                </div>
              </Link>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
