import { Clusters } from "@clustersxyz/sdk";
import {Address} from "viem";
import {useQuery} from "@tanstack/react-query";

const clusters = new Clusters();

export const useClusterName = ({address, enabled = true}: {address?: Address | undefined, enabled?: boolean | undefined}) => {
  const query = useQuery({
    queryKey: ['cluster-name', address],
    queryFn: async () => {
      return clusters.getName(address!);
    },
    enabled: !!address && enabled,
  });

  return query;
}